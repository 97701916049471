/* Custom CSS for tab panes */
.tab-pane.active {
    height :100vh;
}


.tabsDetailView {
    width: calc(100vw - 150px); /* default */
  }

  .boxDetailView {
    width: calc(100vw - 140px); /* default */
  }
  
  @media (min-width: 900px) { /* example breakpoint for md screens */
.tabsDetailView {
      width: calc(100vw - 420px);
    }

.boxDetailView {
        width: calc(100vw - 410px); /* default */
      }
  }


  .image-container {
    position: relative;
    width: 200px; /* Set the width of the thumbnail */
    height: 200px; /* Set the height of the thumbnail */
  }
  
  .thumbnail {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .larger-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  
.larger-image {
    opacity: 1;
  }




/* Style global pour le texte primaire des ListItemText */
.list-item-main-info.MuiListItemText-primary {
  font-size: 8pt;
  font-weight: bold;
  color: red;
}


.MuiListItemText-secondary.list-item-main-info{
  font-size: 8pt;
  font-weight: bold;
}